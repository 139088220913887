import { NineNavigation } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { signupHeaderHeightDesktop } from "src/constants/layout";
import Path from "src/constants/path";
import SignupCubit from "src/state/SignupCubit/SignupCubit";
import useUserAuthenticatedState from "src/state/hooks/useUserAuthenticatedState";
import { BlocProvider } from "src/state/state";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";
import FunnelAuthStep from "src/ui/components/FunnelAuthStep/FunnelAuthStep";
import Translate from "../Translate/Translate";

const StandaloneContent = styled.div`
  label: StandaloneContent;
  width: 100%;
  min-height: 100vh;
  height: 100svh;
  padding-top: ${signupHeaderHeightDesktop}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StandaloneHeader: FC = () => {
  return (
    <NineNavigation
      app
      variant="stepper"
      style={{ background: `var(--color-cream)` }}
    >
      <nine-progress-bar slot="progress"></nine-progress-bar>

      <nine-overlay slot="help" variant="toast">
        <div slot="trigger" className="strong">
          <Translate msg="help.question" />
        </div>
        <div slot="content">
          <h5 className="strong m0">
            <Translate msg="help.questions" />
          </h5>
          <p className="lauf-text">
            <Translate msg="help.phoneSupport" />{" "}
            <a href="tel:2029329958">(202) 932-9958</a>
            <br />
            <Translate msg="help.emailSupport" />{" "}
            <a href="mailto:support@join9am.com">support@join9am.com</a>
          </p>
        </div>
      </nine-overlay>
    </NineNavigation>
  );
};

const StandaloneQuestionnaire: FC<{ enforceAuth?: boolean }> = ({
  enforceAuth = false
}) => {
  const { id = "" } = useParams<{ id: string }>();
  const isAuthenticated = useUserAuthenticatedState();
  const [showAuth, setShowAuth] = useState(enforceAuth);

  useEffect(() => {
    if (!enforceAuth) return;
    setShowAuth(!isAuthenticated);
  }, [isAuthenticated, enforceAuth]);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: `var(--color-cream)`
      }}
    >
      <div style={{ width: "100%" }}>
        <StandaloneHeader />
        <StandaloneContent className="wrap-center">
          {showAuth ? (
            <BlocProvider bloc={new SignupCubit()}>
              <FunnelAuthStep
                controlled
                initialStep="login"
                registerLink={Path.join}
              />
            </BlocProvider>
          ) : (
            <CustomQuestionnaire
              autoSkip={true}
              showThankYouStep
              resetScrollOnEachStep
              id={id}
              useRouting
              onSubmit={async (): Promise<boolean> => {
                return false;
              }}
            />
          )}
        </StandaloneContent>
      </div>
    </div>
  );
};

export default StandaloneQuestionnaire;
